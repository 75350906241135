import { useLoaderData, Link } from "react-router-dom";
import { useContext } from "react";
import { Div } from "../components/Elements";
import Icon from "../components/Icon";
import RouteFor from "../modules/RouteFor";
import ShareButtons from "../components/ShareButtons";
import { Desktop, DesktopClass } from "../modules/useResponsive";
import { RadioContext } from "../modules/RadioProvider";

import _ from "lodash";

export default function ListenShowEpisode() {
  var { show, episode } = useLoaderData();

  if (!_.isArray(episode.songs)) {
    episode.songs = [];
  }

  var showImage =
    _.get(show, "imageLarge") ||
    _.get(show, "imageMedium", "/images/fallback.jpg");

  var image = _.get(episode, 'imageMedium') || _.get(episode, 'show.image') || '/images/fallback.jpg';

  const { playEpisode } = useContext(RadioContext);

  function EpisodeDetail() {
    
      return (
        <>
          <Div className="subtitle">
            {episode.host ? <div>Hosted by <b>{episode.host}</b></div> : <></>}
            <div>Originally Aired On {episode.date} </div>
          </Div>
          <Div className="description" > {episode.description} </Div>
          <Div d={{marginTop:"34pxm"}} m={{marginTop:"19px"}}>
            {episode.songs.length > 0 && <h3>Playlist</h3>}
            {episode.songs.map((entry,i) => {
              return (
                <Div key={i} style={{marginBottom:"0.5em"}}>
                  <Div style={{fontWeight:"bold"}} >{entry.song}</Div>
                  <Div >{entry.artist} • <span style={{fontStyle:"italic"}}>{entry.title}</span></Div>
                </Div>
              )
            })}
          </Div>
          {episode.tags.length ?
            (<Div className="tags" html={'('+episode.tags.join(", ")+')'}></Div>) : <></>}
          <Div className='socials-and-button'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <ShareButtons route={RouteFor({ episode: episode })} />
            <Link to={RouteFor({ show })}><button className='light inline'>find more</button></Link>
          </Div>
    
        </>
      )
    }


  //  console.log("showImage", showImage);
  return (
    <div className="ListenShow full-bleed" style={{ marginTop: 0 }}>
      <div className="meets-edge" style={{ zIndex: 0, position: "relative" }}>
        <div
          style={{
            position: "fixed",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${showImage || "/images/fallback.jpg"})`
          }}
        />
      </div>
      <div
        className="showGradient meets-edge"
        style={{
          display: "flex",
          color: "white",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <div className={DesktopClass("centre-column")}>
          <Div className={`Tile episode detail ${Desktop() ? "Desktop" : "Mobile"}`}>
            

            <div className="gradient">
              <div
                style={{
                  display: "flex",
                  alignItems: Desktop() ? "flex-end" : "flex-start",
                  flexDirection: Desktop() ? "row" : "column",
                  gap: Desktop() ? "16px" : "25px",
                  width: "100%"
                }}
              >
                <img
                  className="episodeImage"
                  src={image}
                  alt={episode.name}
                ></img>
                <div
                  style={{
                    width: Desktop() ? "auto" : "100%"
                  }}
                >
                  {episode.audio ? (
                    <Icon
                      PlayCircle
                      onClick={() => playEpisode({ episode })}
                      style={{ cursor: "pointer" }}
                    >
                      play
                    </Icon>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Div style={{marginTop:'34px'}} size={"28/20"} lineclamp={1} className="bold" >
                {episode.title}
              </Div>
                <EpisodeDetail episode={episode} />
            </div>
          </Div>
        </div>
      </div>
      <div className="ShowPageContainer meets-edge">
        <Div
          className={DesktopClass("centre-column")}
          style={{
            marginBottom: "78px"
          }}
          d={{
            marginBottom: "72px"
          }}
        ></Div>
      </div>
    </div>
  );
}
