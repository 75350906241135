
import { Div, Img, Header } from "./Elements"
import { useRef, useState, useEffect, useCallback } from "react";
import { Desktop, Mobile } from "../modules/useResponsive";
import Icon from "./Icon";
import { isArray, isObject, isEmpty, isNaN, isNumber } from 'lodash';
import { PlayerScrubber, PlayPause, VolumeSlider, LoadingIndicator } from "./PlayerControls";

import _ from "lodash";
import "./Player.scss";
import * as LocalStorageWithFallback from "../modules/LocalStorageWithFallback";

const STREAM_URL = 'https://live.citr.ca/live.aac';

export default function Player({ playable, shouldPlay, setShouldPlay }) {

  var initialVolume = LocalStorageWithFallback.get('volume', 0.5);

  const [volume, setVolume] = useState(1 * initialVolume);

  const [isPlaying, setIsPlaying] = useState(false);
  const [timeInfo, setTimeInfo] = useState({ current: 0, end: 0 })
  const [loading, setLoading] = useState(false);
  
  const [audioSource, setAudioSource] = useState(null);

  var showNameFallback = 'CiTR 101.9fm';
  var imageFallback = 'images/citr-logo-black.svg';
  var episodeNameFallback = 'broadcast';
  var audioRef = useRef(null);

  useEffect(() => {
    audioRef.current.volume = volume;
    LocalStorageWithFallback.set('volume', volume);
  }, [volume]);

  function play() {
    console.log('Play()');
    audioRef.current.play();
  }

  function pause() {
    audioRef.current.pause();
  }

  function onPlay() {
    console.log('onPlay()');
    setLoading(false);
    setIsPlaying(true);
  }

  function onPause() {
    setIsPlaying(false);
  }

  function onScrub(value) {
    audioRef.current.currentTime = value;
    var newTimeInfo = {
      ...timeInfo,
      current: value
    };
    setTimeInfo(newTimeInfo);
  }

  function onScrubEnd() {
  }

  function formatTime(seconds) {
    var minutes = Math.floor(seconds / 60);
    var secondsPart = Math.floor(seconds % 60);
    var returnedSeconds = secondsPart < 10 ? `0${secondsPart}` : `${secondsPart}`;
    return `${minutes}:${returnedSeconds}`;
  }

  function onTimeUpdate() {

    if (!isLive() && audioRef.current && audioRef.current.currentTime) {
      var current = Math.round(audioRef.current.currentTime);

      if (isNumber(current) && !isNaN(current)) {
        var newTimeInfo = {
          end: audioRef.current.duration,
          current
        };
        setTimeInfo(newTimeInfo);
      }
    }
    setLoading(false);
  }

  function onLoadedMetadata() {
    setTimeInfo(oldTimeInfo => {

      var displayedDuration = Infinity;
      if (audioRef.current && audioRef.current.duration) {
        var duration = audioRef.current.duration;
        if (isNumber(duration) && !isNaN(duration)) {
          displayedDuration = duration;//formatTime(duration);//
        }
      }
      var newTimeInfo = { ...oldTimeInfo, end: Math.round(displayedDuration) };
      return newTimeInfo;
    });

  }

  function onCanPlay() {
    setLoading(false);
  }
  function onWaiting() {
    setLoading(true);
  }

  const isLive = useCallback(() => {
    return isArray(playable);
  }, [playable]);


//  const isLive = function () {
//    return isArray(playable);
//  };

  const showName = useCallback(() => {
    if (isEmpty(playable)) return showNameFallback;
    var o = isLive() ? _.first(playable) : _.get(playable, 'episode.show');

    return o && o.title ? o.title : showNameFallback;
  }, [playable, isLive, showNameFallback]);

  const image = useCallback(() => {
    if (isEmpty(playable)) return imageFallback;
    var o = isLive() ? _.first(playable) : _.get(playable, 'episode.show');
    return o && o.image ? o.image : imageFallback;
  }, [playable, isLive, imageFallback]);

  const episodeName = useCallback(() => {
    if (isEmpty(playable)) return episodeNameFallback;
    var o = isLive() ? _.get(_.first(playable),'episode') : playable.episode;
    return o && o.title ? o.title : episodeNameFallback;
  }, [playable, isLive, episodeNameFallback]);

  const refreshMediaSession = useCallback(function () {
//    console.log("refresh media session");
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: showName(), //TODO convert html entities
        artist: episodeName(),
        album: 'citr.ca',
        artwork: [
          { src: image() },
        ]
      });
      navigator.mediaSession.setActionHandler('play', () => {
        audioRef.current.play()
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        audioRef.current.pause()
      });
      navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        audioRef.current.currentTime = audioRef.current.currentTime - (details.seekOffset || 10);
      });
      navigator.mediaSession.setActionHandler('seekforward', (details) => {
        audioRef.current.currentTime = audioRef.current.currentTime + (details.seekOffset || 10);
      });
      navigator.mediaSession.setActionHandler('seekto', (details) => {
        if (details.fastSeek && 'fastSeek' in audioRef.current) {
          audioRef.current.fastSeek(details.seekTime);
        } else {
          audioRef.current.currentTime = details.seekTime;
        }
      });
      navigator.mediaSession.setActionHandler('stop', () => {
        audioRef.current.currentTime = 0;
      });
    }
  }, [episodeName, image, showName]);


  useEffect(() => {
    var element = audioRef.current;
    element.addEventListener('timeupdate', onTimeUpdate);
    return () => {
      element.removeEventListener('timeupdate', onTimeUpdate);
    }
  });

  useEffect(() => {

    var source;
//    console.log("playable", playable);
    if (!playable) {
 //     console.log('nothin playable');
    } else if (isArray(playable)) {
      //live
      setAudioSource(STREAM_URL);
    } else if (isObject(playable)) {
      setAudioSource(playable.episode.audio);
    }
    refreshMediaSession();
    return source;
  },[playable]);

  useEffect(() => {
    var element = audioRef.current;
//    console.log("audio source", audioSource);
//      console.log('setup audio');
      element.addEventListener('playing', onPlay);
      element.addEventListener('pause', onPause);
      element.addEventListener('loadedmetadata', onLoadedMetadata);
      element.addEventListener('waiting', onWaiting);
      element.addEventListener('stalled', onWaiting);
      element.addEventListener('canplay', onCanPlay);
      /*'waiting suspend stalled seeking seeked playing loadstart loadeddata emptied ended canplay abort'.split(' ').forEach(name => {
        element.addEventListener(name, report);
      })*/
    return () => {
//      console.log('takedown audio');
      element.removeEventListener('playing', onPlay);
      element.removeEventListener('pause', onPause);
      element.removeEventListener('loadedmetadata', onLoadedMetadata);
      element.removeEventListener('waiting', onWaiting);
      element.removeEventListener('stalled', onWaiting);
      element.removeEventListener('canplay', onCanPlay);

      /*'waiting suspend stalled seeking seeked playing loadstart loadeddata emptied ended canplay abort'.split(' ').forEach(name => {
        element.removeEventListener(name, report);
      })*/
    }

  }, [audioSource]);

  useEffect(() => {
    if (shouldPlay) {
   //   if (isLive()) {
//        audioRef.current.load();
//        audioRef.current.play();
  //    } else {

        setLoading(true);
        audioRef.current.removeEventListener('loadeddata', play);
        audioRef.current.addEventListener('loadeddata', play);
        audioRef.current.load();
      //}
        setShouldPlay(false);
    }
  }, [shouldPlay, setShouldPlay])

  function upNext() {
    if (isArray(playable) && playable.length > 1 && _.get(playable, '1.title')) {
      return playable[1];
    } else {
      return {
        name: '...'
      };
    }
  }

  function LiveNowIndicator() {
    return <Div flex="row center center"
      style={{
        textTransform: 'uppercase',
        fontSize: '13px',
        gap: '4px'
      }}>
      <Div style={{ color: '#ea5f3f', fontSize: '21px', lineHeight: '11px', paddingBottom: '1px' }}>•</Div> live now
    </Div>
  }



  const [expandedForMobileScrubbing, setExpandedForMobileScrubbing] = useState(false);
  var timeoutForExpandingForMobileScrubbing = useRef();

  useEffect(() => {
    if (isLive()) {
      return;
    }

  }, [expandedForMobileScrubbing, isLive]);

  function toggleExpandedForScrub() {
    if (isLive()) {
      return;
    }
    if (!timeoutForExpandingForMobileScrubbing.current) {
      setExpandedForMobileScrubbing(true);
      console.log('expand and wait 5 seconds');
    } else if (expandedForMobileScrubbing) {
      console.log('clear the timeout');
      clearTimeout(timeoutForExpandingForMobileScrubbing.current);
      timeoutForExpandingForMobileScrubbing.current = null;
      //toggleExpandedForScrub();
    }

    timeoutForExpandingForMobileScrubbing.current = setTimeout(() => {
      setExpandedForMobileScrubbing(false);
      timeoutForExpandingForMobileScrubbing.current = null;
    }, 5000);

  }

  return (
    <Div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      borderTop:"1px solid black",
      background: 'white',
      zIndex: 2,
    }} m={{
      borderRadius: expandedForMobileScrubbing ? '10px 10px 0px 0px' : '0',
      height: expandedForMobileScrubbing ? '120px' : '53px',
      transition: 'height 0.5s ease-in-out, border-radius 0.5s ease-in-out'
    }}>
{/*}      <pre>json {JSON.stringify(playable,null,2)}</pre>{*/}
      <Mobile>
        <Div style={{
          height: expandedForMobileScrubbing ? '70px' : '0',
          padding: '8px 16px 16px 16px'
        }}
          onClick={toggleExpandedForScrub}>
          <PlayerScrubber timeInfo={timeInfo} onScrub={onScrub} onScrubEnd={onScrubEnd} />

          <Div size="13">
            {formatTime(timeInfo.current)}<span style={{ color: 'var(--legible-grey)' }}>/
              {formatTime(timeInfo.end)}</span>
          </Div>
        </Div>
      </Mobile>
      <Div flex='row space-between center'
        style={{
          background: 'white'
        }}
        d={{ paddingLeft: '50px', paddingRight: '50px' }}
        m={{
          position: 'absolute',
          bottom: 0,
          width: '100%'
        }}
        onClick={Mobile() ? () => {
          //if (Mobile()) {
          toggleExpandedForScrub()
          //expandForMobileScrub();
          //}
        } : null}>
        <Div
          flex='row flex-start center'
          style={{ gap: '16px' }}
          d={{ paddingRight: '32px', paddingTop: '4px', paddingBottom: '4px' }}
        >

          <Desktop>
            {isLive() && !loading ? <LiveNowIndicator /> : <></>}
            {loading ? LoadingIndicator() :
              <PlayPause small pause={pause} play={play} isPlaying={isPlaying} />}
          </Desktop>
          <Img
            d={{ width: '44px', height: '44px' }}
            m={{ width: '62px', height: '62px' }}
            src={image()}
            style={{ objectFit: 'cover' }}
          />
          <Div flex='column'>
            <Header size="13" style={{ whiteSpace: 'nowrap' }} html={episodeName()}></Header>
            <Header size="16" style={{ whiteSpace: 'nowrap' }} className={'bold'} html={showName()}>
            </Header>
            {/*}         <pre style={{ overflow: 'scroll', height: '8em', width: '20em', fontSize: '0.8em' }}>{JSON.stringify(playable, null, 2)}</pre>
{*/}
          </Div>
        </Div>
        <Desktop>
          {isLive() ? 
            (upNext().title ?
              (<Div d={{
                borderLeft: '2px solid black',
                paddingLeft: '32px',
                flexGrow: 2
              }}>
                <Div size="13" style={{ whiteSpace: 'nowrap' }}>Up Next <Icon Clock /> {upNext().formattedTime}</Div>
                <Div size="16" style={{ whiteSpace: 'nowrap' }} className={'bold'} html={upNext().title}></Div>
              </Div>)
              :
              null)
            :
            (<Div
              flex='row flex-start center'
              style={{
                width: '100%',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              <Div>
                {formatTime(timeInfo.current)}
              </Div>
              <Div flex='column center' style={{ width: '100%', margin: '16px' }}>
                <PlayerScrubber timeInfo={timeInfo} onScrub={onScrub} onScrubEnd={onScrubEnd} />
              </Div>
              <Div>
                {formatTime(timeInfo.end)}
              </Div>
            </Div>)}
        </Desktop>

        <audio
          ref={audioRef}
          src={audioSource}
          preload="metadata"
          autoPlay={false}
        />

        <Div
          flex="row space-between center"
          style={{ gap: '16px' }}
          m={{ paddingRight: '18px' }}>
          <Mobile>
            {loading ? LoadingIndicator() :
              <PlayPause small pause={pause} play={play} isPlaying={isPlaying} />
            }
          </Mobile>
          <Desktop>
            <VolumeSlider volume={volume} setVolume={setVolume} />
          </Desktop>
          {/*}        <Icon Cast />{*/}
        </Div>
      </Div>
    </Div>
  );
}