
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faXmark,
  faChevronRight,
  faArrowRight,
  faCaretDown,
  faCalendarDay,
  faPlay,
  faPause,
  faVolumeHigh,
  faVolumeLow,
  faVolumeOff,
  faHourglassHalf,
  faLink,
  faFilter,
  faArchive
} from "@fortawesome/free-solid-svg-icons";

import {
  faCirclePlay,
  faClock
} from "@fortawesome/free-regular-svg-icons"
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
  faChromecast,
  faSpotify,
  faBandcamp,
  faSoundcloud



} from "@fortawesome/free-brands-svg-icons";


function StyledIcon({ icon, h, w, props, style, className }) {
  style = {
    ...style,
    height: h ? `${h}px` : 'auto',
    width: w ? `${w}px` : 'auto',
    ...props.style,
  }
  var altFallback = _.get(icon, 'iconName', 'icon').replace('-', ' ');
  var alt = _.get(props, 'alt', altFallback);
  return <FontAwesomeIcon
    className='Icon fa-fw'
    icon={icon}
    style={style}
    onClick={props.onClick} alt={alt} />;
}

const icons = {
  Search: (props) => <StyledIcon props={props} icon={faMagnifyingGlass} h={17.5} w={17.5} />,
  Close: (props) => <StyledIcon props={props} icon={faXmark} h={20} w={20} />,
  Menu: (props) => <StyledIcon props={props} icon={faBars} h={20} />,
  ChevronRight: (props) => <StyledIcon props={props} icon={faChevronRight} h={12} />,
  ArrowRight: (props) => <StyledIcon props={props} icon={faArrowRight} h={12} w={12} />,//  { url: "https://via.placeholder.com/12x12", style: {} },
  ArrowDown: (props) => <StyledIcon props={props} icon={faCaretDown} w={10} />, //{ url: "https://via.placeholder.com/10x5", style: {} },
  Calendar: (props) => <StyledIcon props={props} icon={faCalendarDay} w={15} />,//{ url: "https://via.placeholder.com/15x16", style: { margin: "0em" } },
  Instagram: (props) => <StyledIcon props={props} icon={faInstagram} h={16} />,//{ url: "https://via.placeholder.com/16x16" },
  Facebook: (props) => <StyledIcon props={props} icon={faFacebook} h={16} />,// { url: "https://via.placeholder.com/16x16" },
  Twitter: (props) => <StyledIcon props={props} icon={faTwitter} w={20} />,//{ url: "https://via.placeholder.com/20x16" },
  Youtube: (props) => <StyledIcon props={props} icon={faYoutube} w={23} />,//{ url: "https://via.placeholder.com/23x16" },
  Spotify: (props) => <StyledIcon props={props} icon={faSpotify} />,
  Bandcamp: (props) => <StyledIcon props={props} icon={faBandcamp} />,
  SoundCloud: (props) => <StyledIcon props={props} icon={faSoundcloud} />,
  Link: (props) => <StyledIcon props={props} icon={faLink} w={20} />,
  PlayCircle: (props) => <StyledIcon props={props} className='fa-regular' icon={faCirclePlay} h={72} />,//{ url: "https://via.placeholder.com/72x72", style: { cursor: 'pointer' } },
  Play: (props) => <StyledIcon props={props} icon={faPlay} h={21} />,//{ url: "https://via.placeholder.com/9x11" },
  PlaySmall: (props) => <StyledIcon props={props} icon={faPlay} h={16} />,//{ url: "https://via.placeholder.com/9x11" },
  HourglassHalf: (props) => <StyledIcon props={props} icon={faHourglassHalf} h={16} />,//{ url: "https://via.placeholder.com/21x20" },
  VolumeMuted: (props) => <StyledIcon props={props} icon={faVolumeOff} h={21} />,//{ url: "https://via.placeholder.com/21x20" },
  VolumeLow: (props) => <StyledIcon props={props} icon={faVolumeLow} h={21} />,//{ url: "https://via.placeholder.com/21x20" },
  VolumeHigh: (props) => <StyledIcon props={props} icon={faVolumeHigh} h={21} />,//{ url: "https://via.placeholder.com/21x20" },
  Cast: (props) => <StyledIcon props={props} icon={faChromecast} w={25} />,//{ url: "https://via.placeholder.com/25x20" },
  Clock: (props) => <StyledIcon props={props} icon={faClock} h={11} style={{ paddingBottom: '1px' }} />,//{ url: "https://via.placeholder.com/10x10" },
  Pause: (props) => <StyledIcon props={props} icon={faPause} h={21} />,//{ url: "https://via.placeholder.com/72x72", style: { cursor: 'pointer' } },
  PauseSmall: (props) => <StyledIcon props={props} icon={faPause} h={16} />,//{ url: "https://via.placeholder.com/72x72", style: { cursor: 'pointer' } },
  Filter: (props) => <StyledIcon props={props} icon={faFilter} h={16} />,
  Archive: (props) => <StyledIcon props={props} icon={faArchive} h={16} />
};
var availableIcons = Object.keys(icons);

export default function Icon(props) {

  var theIcon = Object.keys(props).find(key => availableIcons.includes(key));
  if (icons[theIcon] && icons[theIcon].url) {
    return <img
      className="Icon"
      src={icons[theIcon].url}
      alt={`${theIcon} icon`}
      style={
        {
          ...icons[theIcon].style,
          ...(props.style || {}),
          ...{
            objectFit: 'contain'
          }
        }}
      onClick={props.onClick}
    />;
  } else if (icons[theIcon]) {
    return icons[theIcon](props);
  } else {
    return <div style={{ maxWidth: '1em', display: 'inline' }}>[?]</div>
  }
}