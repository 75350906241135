import { Link, useLoaderData } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import React from "react";
//import moment from 'moment';

import Data from "../modules/Data";
//import { Desktop, Mobile } from '../modules/useResponsive';

import HorizontalScroller from "../components/HorizontalScroller";
import Grid from "../components/Grid";
import ShowCard from "../components/ShowCard";
import EpisodeCard from "../components/EpisodeCard";
import Album from "../components/Album";
import Icon from "../components/Icon";
import { Div, Header, Button, StyledLink } from "../components/Elements";
import { Desktop } from "../modules/useResponsive";

import "../TopAlbums.scss";
import { RadioContext } from "../modules/RadioProvider";

export default function Listen() {
  const [Albums] = useLoaderData();

  const [Shows, setShows] = useState([]);
  const [lastShowsKey, setLastShowsKey] = useState(null);
  //  const [Albums, setAlbums] = useState([]);

  const { playLiveStream } = useContext(RadioContext);
//  console.log('shows', initialShows)
  var isDesktop = Desktop();

  useEffect(() => {

    return Data.subscribe("citr/today", (data) => {
//      console.log("found today", data);

      if (data.shows !== lastShowsKey) {
        try {
          var parsed = JSON.parse(data.json);
          setShows(parsed);
          setLastShowsKey(data.show);
        } catch (e) {
          console.error(e);
        } 
      }
    });
  },[]);


  var showCardStyle = isDesktop
    ? {
        width: "calc(calc(calc(100vw - 100px) * 0.25) - 16px)",
        aspectRatio: 1
      }
    : {
        aspectRatio: 1,
        width: "83.3%",
        height: "auto"
      };

  return (
    <div>
      <Div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          marginTop: Desktop() ? "29px" : ""
        }}
        m={{
          alignItems: "center"
        }}
      >
        <Header
          style={{ fontWeight: "bold" }}
          d={{
            fontSize: "48px",
            lineHeight: "72px"
          }}
          m={{
            fontSize: "20px",
            lineHeight: "30px",
            textTransform: "uppercase"
          }}
        >
          On Air Today
        </Header>
        <StyledLink to="/program-guide" d={{ marginTop: "15px" }}>
          <Header size="16/13" className={isDesktop ? "bold upper" : "upper"}>
            Program Guide
          </Header>
        </StyledLink>
      </Div>
      {Shows && Shows.length ? (
        <HorizontalScroller
          d={{
            marginBottom: "120px",
            marginTop: "29px"
          }}
          m={{
            marginBottom: "36px",
            marginTop: "19px"
          }}
        > {Shows[0] &&
          <ShowCard
            player
            show={Shows[0]}
            style={showCardStyle}
            thumbnail
            titleSize="20"
            className="keen-slider__slide"
          ></ShowCard>
      }
          {Shows.slice(1).map((show) => {
            if (!show) {
              return null;
            }
            if (show.isLive) {
              show = {
                ...show.show,
                episode: show.episode,
                next: show.next,
                isLive: true
              };
            }
            return (
              <ShowCard
                show={show}
                style={showCardStyle}
                thumbnail
                titleSize="20"
                className="keen-slider__slide"
                key={show.id}
              />
            );
          })}
        </HorizontalScroller>
      ) : (
        <Button
          onClick={playLiveStream}
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          Listen Live
        </Button>
      )}

      <h1>Latest from CiTR</h1>
      <Grid
        m={{ marginTop: "36px", gap: "18px" }}
        buttonStyle={
          Desktop()
            ? {}
            : {
                marginTop: "36px",
                marginBottom: "46.17px"
              }
        }
        childElementFn={(x) => <EpisodeCard episode={x} key={x.id} />}
        loadFn={Data.episodes.latest}
      ></Grid>
      <hr />
      {Albums.length > 0 ? (
        isDesktop ? (
          <Div
            className="TopAlbums meets-edge Desktop"
            flex="row space-between flex-start"
          >
            <div
              className="TopAlbum"
              style={{
                height: "100%",
                width: "50vw",
                backgroundImage: `url(${Albums[0].imageLarge})`
              }}
            >
              <div
                className="darkGradient"
                style={{
                  height: "100%",
                  position: "relative"
                  // display: 'flex',
                  //   flexDirection: 'row',
                }}
              >
                <Div
                  style={{
                    padding: "50px",
                    position: "relative",
                    height: "100%",
                    // border: '1px solid lime',
                    zIndex: 2
                  }}
                  flex="column space-between"
                >
                  <h1>Weekly Top Albums</h1>
                  <img
                    src={Albums[0].imageLarge}
                    style={{
                      width: "50%"
                    }}
                    alt={Albums[0].name}
                  />
                  <Album
                    album={Albums[0]}
                    number={1}
                    style={{ marginTop: "24px" }}
                    isNumberOne
                  />
                </Div>

                <div
                  className="lightGradient"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    height: "100%",
                    //                    border: '1px solid lime',
                    width: "15.5vw",
                    zIndex: 1
                  }}
                ></div>
              </div>
            </div>
            <Div
              className="RestOfAlbums Desktop"
              flex="column flex-end"
              style={{ flexGrow: "1" }}
            >
              <Div flex="column center" style={{ height: "50px" }}>
                <Link to="/charts">
                  <h3>Full Chart</h3>
                </Link>
              </Div>
              {Albums.slice(1, 5).map((a, i) => (
                <Album album={a} key={i} number={i + 2} />
              ))}
            </Div>
          </Div>
        ) : (
          <div
            className="TopAlbums meets-edge Mobile"
            style={{
              backgroundImage: `url(${Albums[0].imageLarge})`
            }}
          >
            <div className="darkGradientMobile">
              <Div
                d={{
                  padding: "16px",
                  zIndex: 2
                }}
                m={{
                  padding: "16px 16px 0px 16px"
                }}
              >
                <h1>Top Albums of the Week</h1>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <img
                    className="numberOneCover"
                    style={{
                      aspectRatio: 1,
                      width:"calc(50% - 8px)"
                    }}
                    src={Albums[0].image}
                    alt={Albums[0].name}
                  />
                  <Album album={Albums[0]} number={1} isNumberOne />
                </div>
              </Div>

              <div className="RestOfAlbums Mobile">
                {Albums.slice(1, 5).map((a, i) => (
                  <Album album={a} key={i} number={i + 2} />
                ))}
              </div>

              <h3
                style={{
                  color: "white",
                  textAlign: "right",
                  margin: "0px 16px",
                  padding: "48px 0px"
                }}
              >
                <StyledLink
                  to="/charts"
                  style={{
                    textAlign: "right"
                  }}
                >
                  Full Chart <Icon ChevronRight style={{ marginLeft: "8px" }} />
                </StyledLink>
              </h3>
            </div>
          </div>
        )
      ) : (
        <div>no albums found</div>
      )}
    </div>
  );
}
