import { Div, Header, StyledLink } from "../components/Elements";
import Grid from "../components/Grid";
import ShowCard from "../components/ShowCard";
import ColumnLink from "../components/ColumnLink";
import Icon from "../components/Icon";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { CustomDurationFormat } from "../modules/CustomDateTimeFormats.mjs";
import Data from "../modules/Data";
import _ from "lodash";
import RouteFor from "../modules/RouteFor";
import { Desktop } from "../modules/useResponsive";

import { useLoaderData } from "react-router-dom";

function dateFormatted(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
}

function makeFromToday(scheduleInput){
  var fromToday = [];
  
  _.each(scheduleInput, (day, i) => {
    var date = DateTime.fromISO(day.date);
    var isToday = date.hasSame(DateTime.now(), "day");
    var isLaterThanToday = date > DateTime.now();
    if (isToday || isLaterThanToday) {
      fromToday.push(day);
    }
  });
//  console.log("scheduleInput", fromToday);
  return fromToday;
}
// dev aid: turn on to see borders on everything
const devLayout = false;

function ProgramGuide() {


  var { allGenres, scheduleFallback } = useLoaderData();

  const [schedule, setSchedule] = useState(makeFromToday(scheduleFallback));
  const [focusSchedule, setFocusSchedule] = useState(
    !window.location.hash.includes("genres")
  );
  const [todayPrevShows] = useState([]);

  const [selectedGenres, setSelectedGenres] = useState(
    _.compact([new URLSearchParams(window.location.search).get("genre")])
  );

  const [filter, setFilter] = useState("");
  const [includeArchived, setIncludeArchived] = useState(false);

  const [r1] = useState(_.random(1, 4));

  useEffect(() => {
    if (focusSchedule) {
      window.location.hash = "";
    } else {
      window.location.hash = "genres";
    }
  }, [focusSchedule]);

  useEffect(() => {
    
    return Data.subscribe("citr/daysmini", (data) => {
      if (!data || !data.json) return;
      Data.scheduleSlotMap().then((slotMap) => {
        try {
          console.log("subscribed-to schedule", data.json);
          var parsedDays = JSON.parse(data.json);
          //        console.log("got schedule. today is:", _.get(parsed, 'days.0.schedule') );
          var schedule = _.map(parsedDays, (day) => {
            return {
              ...day,
              schedule: _.map(day.schedule, (entry) => {
                return {
                  ...entry,
                  ..._.get(slotMap, entry.key,{title:entry.title}),
                  start: DateTime.fromISO(day.date).plus({hours: entry.s}).toISO(),
                  end: DateTime.fromISO(day.date).plus({hours: entry.e}).toISO()
                };
              })
            };
          });
          
          setSchedule(makeFromToday(schedule));
        } catch (e) {
          console.error(e);
        }
        
      });
    });
  }, []);

  function Genres({ d, m, selectedGenres, setSelectedGenres }) {

    const [loading, setLoading] = useState(false);

    const [genreShowResults, setGenreShowResults] = useState([]);
    const [genres] = useState(allGenres);

    useEffect(() => {
      if (_.size(selectedGenres) > 0) {
        setLoading(true);
        setGenreShowResults([]);
        Data.shows
          .showsWithGenre(selectedGenres[0])
          .then((data) => {
            setGenreShowResults(data);
            setLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setLoading(false);
          });

        // set get query genres
        //          var params = new URLSearchParams(window.location.search);
        //         params.set("genre", selectedGenres[0]);
        //       } else {
        //        var params = new URLSearchParams(window.location.search);
        //       params.delete("genre");
      }
    }, [selectedGenres]);

    var isDesktop = Desktop();

    var linkStyles = {
      d: {
        padding: "0",
        //      marginBottom: "24px",
        lineHeight: "43px"
      },
      m: {
        padding: "0",
        lineHeight: "30px",
        width: "auto",
        fontSize: "20px"
      }
    };

    function GenresList() {
      var genresList = genres;
      if (filter.length > 0) {
        genresList = _.filter(genres, (genre) => {
          return genre.name.toLowerCase().includes(filter.toLowerCase());
        });
      }

      if (_.size(genresList) > 0) {
        return genresList.map((genre) => (
          <ColumnLink
            key={`${genre.name}-${genre.slug}`}
            name={genre.name}
            d={linkStyles.d}
            m={linkStyles.m}
            onClick={() => {
              var cleanName = _.replace(genre.name, "&amp;", "&");
              console.log("genre", cleanName);
              setSelectedGenres(() => [cleanName]);
              setFocusSchedule(false);
            }}
          ></ColumnLink>
        ));
      } else {
        return "no genres found";
      }
    }

    function shouldShowGenresList() {
      return _.size(filter) > 0 || _.size(selectedGenres) === 0;
    }
    return (
      <Div style={{ marginTop: "21px", width: "100%" }} d={d} m={m}>
        <Div
          flex="row end"
          style={{
            float: "right",
            width: "100%",
            height: "2em",
            columnGap: "2em",
            marginBottom: "2em"
          }}
        >
          <input
            style={{
              backgroundColor: "transparent",
              color: "white",
              border: "none",
              borderBottom: "1px solid white"
            }}
            type="text"
            placeholder="search genres"
            value={filter}
            onChange={(e) => {
              setSelectedGenres([]);
              setFilter(e.target.value);
            }}
          />
        </Div>

        
        {selectedGenres.length > 0 && (
          <>
            <Div>
          {selectedGenres.map((g) => {
          return (
            <StyledLink
              key={g}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textTransform: "uppercase"
              }}
              onClick={() => {
                setSelectedGenres(_.filter(selectedGenres, (s) => s !== g));
                //              setGenreShowResults([]);
              }}
            >
              {g}
              {"  "}
              <span style={{ fontSize: "small", marginRight: "20px" }}>x</span>
            </StyledLink>
          );
        })}<br/>
            <input
              id="archive-filter"
              type="checkbox"
              defaultChecked={includeArchived}
              onChange={(e) => setIncludeArchived(!includeArchived)}
              style={{ margin: "1em 0.5em 0.5em 0" }}
              />
            <label htmlFor="archive-filter">include archived shows</label>
          </Div>
          </>
        )}
        <Div
          flex="row"
          style={{
            flexWrap: "wrap",
            //            justifyContent: "space-between",
            columnGap: "18px",
            marginTop: "24px",
            rowGap: "12px",
            width: "100%"
          }}
        >
          {shouldShowGenresList() ? GenresList() : ""}
        </Div>
        {loading ? (
          <Div flex="row center" style={{ margin: "32px" }}>
            {" "}
            loading...{" "}
          </Div>
        ) : (
          ""
        )}
        {selectedGenres && genreShowResults.length ? (
          <Grid
            id={"shows-" + selectedGenres}
            d={{
              marginTop: "36px",
              marginBottom: "137px"
            }}
            m={{
              marginBottom: "103px"
            }}
            pageSize={12}
            childElementFn={(show) => (
              <ShowCard
                show={show}
                icon={
                  show.active ? null : (
                    <Icon
                      Archive
                      style={{
                        background: "grey",
                        padding: "3px",
                        borderRadius: "4px"
                      }}
                    />
                  )
                }
                key={show.id}
                thumbnail
                titleSize="20"
                d={{
                  width: "calc(calc(calc(100vw - 100px) * 0.25) - 16px)",
                  aspectRatio: 1
                }}
                m={{
                  aspectRatio: 1,
                  height: "auto",
                  width: "100%"
                }}
              />
            )}
            initialData={_.sortBy(
              _.filter(genreShowResults, (show) => {
                return show.active || includeArchived;
              }),
              "active"
            ).reverse()}
            buttonStyle={{
              marginTop: isDesktop ? "32px" : "36px",
              marginBottom: isDesktop ? "75px" : "36px",
              display: "none"
            }}
          ></Grid>
        ) : null}
      </Div>
    );
  }

  function ScheduleSlot(entry, j) {
    var showIsPlaying = false;
    if (
      entry.start < DateTime.now().toISO() &&
      entry.end > DateTime.now().toISO()
    ) {
      showIsPlaying = true;
    }
    return (
      <Div
        flex="row start center"
        style={{ 
          border: devLayout?"1px solid lime":"none",
          whiteSpace: "nowrap"
        }}
        d={{
          marginTop: j === 0 ? "24px" : "12px",
          columnGap: "12px",
        }}
        m={{
          marginTop: j === 0 ? "21px" : "10px",
          columnGap: '16px',/*
          alignItems: "start",
          flexWrap: "wrap"*/
        }}
        key={`${entry.show}-${j}-${entry.start}`}
      >
        <Div
          style={{
            display: "inline",
            fontWeight: showIsPlaying ? "bold" : "normal",
            border: devLayout?"1px solid orange" : null,
            textAlign:"right"
          }}
          d={{
            fontSize:"18px",
            minWidth: "150px"
          }}
          m={{
            minWidth: "135px"
          }}
          className={showIsPlaying ? `gradient-text gradient-${r1}` : ""}
        >
          {CustomDurationFormat(entry.start, entry.end)}
        </Div>
        <StyledLink
          to={RouteFor({ show: entry })}
          style={{
            fontWeight: showIsPlaying ? "bold" : "normal"
          }}
          className={
            showIsPlaying
              ? `gradient-text gradient-${r1} color-underline`
              : "color-underline"
          }
        >
          {entry.title}
        </StyledLink>
        {entry.tags && entry.tags.length > 0 ? (
          <span style={{ fontSize: "0.7em", flexGrow:2, border: devLayout?"1px solid red": null }}>
            {_.get(entry, "tags", []).map((genreName, i) => {
              var rand = _.random(1, 4);
              return (
                <span key={`${i}-${genreName}`}>
                  <StyledLink
                    className={`gradient-hover gradient-${rand}-hover`}
                    style={{ padding: "4px" }}
                    name={genreName}
                    onClick={() => {
                      setSelectedGenres((gs) => [genreName]);
                      setFocusSchedule(false);
                    }}
                  >
                    {genreName}
                  </StyledLink>
                  {i < entry.tags.length - 1 ? ", " : " "}
                </span>
              );
            })}
          </span>
        ) : null}
      </Div>
    );
  }

  function Schedule({ schedule, todayPrevShows }) {
    return (
      <>
        <Div
          d={{
            paddingTop: "29px",
            paddingBottom: "50px"
          }}
          m={{
            marginTop: "0px",
            marginBottom: "84px"
          }}
        >
          {schedule.map((day, i) => {
            return (
              <Div
                key={day.date}
                d={{
                  marginTop: i === 0 ? "29px" : "48px",
                  fontSize: "22px"
                }}
                m={{
                  marginTop: i === 0 ? "24px" : "42px"
                }}
              >
                <Header
                  className={"top-border"}
                  d={{
                    fontSize: "25px"
                  }}
                  size="16"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold"
                  }}
                >
                  {dateFormatted(day.date)}
                </Header>
                {day.schedule.map((entry, j) => ScheduleSlot(entry, j))}
              </Div>
            );
          })}
        </Div>
      </>
    );
  }



  //  var { schedule } = useLoaderData();

  return (
    <>
      <Div style={{ color: "white" }}>
        <Header
          size="48/20"
          style={{
            fontWeight: "bold"
          }}
          m={{
            lineHeight: "30px",
            textTransform: "uppercase"
          }}
        >
          <span
            onClick={() => {
              setFocusSchedule(true);
            }}
            style={{
              color: focusSchedule ? "white" : "grey",
              cursor: "pointer"
            }}
          >
            Program Guide
          </span>
          <span
            onClick={() => {
              setFocusSchedule(false);
              //set location hash to genres
            }}
            style={{
              marginLeft: "24px",
              color: focusSchedule ? "grey" : "white",
              cursor: "pointer"
            }}
          >
            Explore Genres
          </span>
        </Header>
        <Div
          style={{ display: "flex", marginTop: "29px" }}
          m={{ marginTop: "24px" }}
        >
          {focusSchedule ? (
            <>
              {_.isArray(schedule) &&
              _.isArray(_.get(_.first(schedule), "schedule")) ? (
                <Schedule schedule={schedule} todayPrevShows={todayPrevShows} />
              ) : (
                <Header style={{ color: "white" }}>
                  Schedule could not be loaded. Please try again later.
                </Header>
              )}
            </>
          ) : (
            <Genres selectedGenres={selectedGenres} setSelectedGenres={setSelectedGenres}/>
          )}
        </Div>
      </Div>
    </>
  );
}
export default ProgramGuide;
