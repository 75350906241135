// Import the css module
import styles from './GutenbergBlockLoop.module.css';

// Used to covert the HTML returned from the Wordpress API into React components
import parse from 'html-react-parser';

function GutenbergBlockLoop(props) {
  const { blocks } = props;

  // This function will loop through all the blocks and create the html for each block and its children
  // Depth is tracked to make sure the key is unique
  // Blocks is passed in here from the WordPress plugin and data is stored in block_data
  function processBlocks(allBlocks, depth = 0) {
    // Loop through all the blocks and create the html for each block and its children
    return allBlocks.map((block, index) => {
      // Convert the html to a React component
      const parsedHTML = parse(block.innerHTML);
      // Convert the block name to a class, remove the slashes and replace with dashes
      const convertedBlockName = "wp-" + block.blockName.replace(/\//g, '-');


      return (
        <div key={`${convertedBlockName}-${depth}-${index}`} className={`${convertedBlockName} ${styles[convertedBlockName] ?? "wp-citr-block"}`}>
          {/* Process the current block and create the HTML, if there is any HTML inside of the element from WP */}
          {block.innerContent[1]!== null ? parsedHTML : "" }

          {/* If the block has innerBlocks, process them until there are no more innerBlocks */}
          {block.innerBlocks && block.innerBlocks.length > 0 && processBlocks(block.innerBlocks, depth + 1)}
        </div>
      );
    });
  }

  return (
    <div className={`wp-citr-block-group ${styles['wp-citr-block-group']}`}>
      {processBlocks(blocks)}
    </div>
  );
}

export default GutenbergBlockLoop;