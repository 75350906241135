import superagent from "superagent";
import _ from "lodash";

var map = {};

export default function CachedRequest(url, query) {

  var key = url + JSON.stringify(query);
  if (!_.isObject(map[key])) {
    map[key] = superagent
      .get(url)
      .withCredentials()
      .query(query)
      .then((response) => {
        _.unset(map, key);
        return response;
      });
  }
  return map[key];
}
