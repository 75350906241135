
import { DateTime,  /*Settings*/ } from "luxon";


function isEnglishLocale(){

  var locale = DateTime.now().locale;
  return locale === 'en-CA' || locale === 'en-US' || locale === 'en';
}
export function CustomDurationFormat(startISO, endISO) {

  //Settings.defaultLocale = 'fr';

  function customCadUSAFormat(dateTime) {
    if (dateTime.minute === 0) {
      return dateTime.toFormat('ha');
    } else {
      return dateTime.toFormat('h:mma')
    }
  }
  var startFormatted, endFormatted = "??";
  if (isEnglishLocale()) {
    startFormatted = customCadUSAFormat(DateTime.fromISO(startISO));
    endFormatted = customCadUSAFormat(DateTime.fromISO(endISO));
  } else {
    var format = DateTime.TIME_SIMPLE;
    startFormatted = DateTime.fromISO(startISO)
      .toLocaleString(format);
    endFormatted = DateTime.fromISO(endISO)
      .toLocaleString(format);
  }

  return `${startFormatted} – ${endFormatted}`;
//  return `8:88PM – 3:33AM`;
}

export function CustomDateFormat(luxonDate){
  if (isEnglishLocale()) {
    return luxonDate.toFormat('MM.dd.yyyy');
  } else {
    return luxonDate.toLocaleString();
  }
}