import { Div, Header } from "../components/Elements";

const { useLoaderData, Link } = require("react-router-dom");

function Blog() {

  var posts = useLoaderData();

  return (
    <Div style={{ marginTop: '21px' }}>
      <Header
        size="16"
        className={"top-border bold"}
        style={{ lineHeight: "24px", textTransform: "uppercase"}}
      >
        Blog Posts
      </Header>
      <Div>

      {posts.map((post) => {


          const dateObj = new Date(post.date);
          const month   = dateObj.getUTCMonth() + 1; // months from 1-12
          const day     = dateObj.getUTCDate();
          const year    = dateObj.getUTCFullYear();
          
          return (
            <Div style={{ margin: "3rem 0"}}>
              <h3>{post.title}</h3>
              <Link to={`/read/blog/${year}/${month}/${day}/${post.slug}`}>Read More</Link>
            </Div>
        )
      })}        

      </Div>
    </Div>
  );
}

export default Blog;
