import _ from "lodash";
import CachedRequest from "./CachedRequest.mjs";

var doRequest = CachedRequest; // lets us override when we import from inside nodeJS

var wpShowProperties = `id
slug
djland_show_id
djland_show_img
djland_name
djland_host_name
djland_show_desc
djland_schedule_summary
djland_social_links
djland_website
djland_active
genres
genres_alt
featured_image
_links
_embedded`.split('\n')


var wpDomain = "/citr.ca";

if (typeof window !== "undefined"){ // because node env imports this file too
  var wp_result = new URLSearchParams(window.location.search).get("wp");
  if (wp_result) {
    wpDomain = wp_result;
  }
}

var base = {
  wp: `${wpDomain}/wp-json/wp/v2/`,
  yarpp: `${wpDomain}/wp-json/yarpp/v1/`,
  admin: `${wpDomain}/wp-admin/`
};

console.log('base', base);

function baseJoin(key, path) {
  return base[key] + path;
}

function jsonApiRequest(path, query, cleanFn) {
  return doRequest(path, query).then((r) => {
    if (_.isFunction(cleanFn)) {
      if (_.isArray(r.body)) {
        return r.body.map(cleanFn);
      } else {
        return cleanFn(r.body);
      }
    } else {
      return r.body;
    }
  });
}
var categoryPostTypeMap = {
  articles: "discorder",
  shows: "radio",
  episodes: "playlist"
};
var WordpressApi = {
  relatedPosts: (id, cleanFn) => {
    return jsonApiRequest(
      baseJoin("yarpp", `related/${id}`),
      {
        _fields: "id,slug,date,tags,title,summary,_links,_embedded",
        _embed: "wp:term",
        limit: 2
      },
      cleanFn
    );
  },
  search: (queryObj, category, cleanFn) => {
    queryObj.subtype = categoryPostTypeMap[category];
    return doRequest(baseJoin("wp","search"), queryObj).then((r) => {
      var ids = _.map(r.body, "id");
      var pages = _.parseInt(_.get(r, "headers.x-wp-totalpages", 1));
      var obj = {
        results: [],
        q: queryObj.search,
        pages
      };

      if (_.isEmpty(ids)) {
        return obj;
      } else {
        
        var defaultFields = "id,slug,date,title,link,excerpt,summary,djland_show_id,djland_show_img,djland_name,featured_image";
        var fields = _.get(queryObj, "fields", defaultFields);
        if (category === "episodes") {
          fields ="";
        }
        return doRequest(baseJoin("wp" , (queryObj.subtype || "post")), {
          include: ids.join(","),
          per_page: queryObj.per_page,
          _fields:fields,
          _embed: "wp:term"
        }).then((r) => {
          var results = _.map(r.body, cleanFn);
          //console.log('results for' + queryObj.search, results);
          return _.extend(obj, { results });
        });
      }
    });
  },
  posts: ({ offset, size }, cleanFn) => {
    var paramObj = {
      offset,
      per_page: size,
      _fields: "id,slug,title,date,excerpt,summary,featured_image,blocks,_links,_embedded",
      _embed: "wp:term"
    };
    return jsonApiRequest(baseJoin("wp", "posts"), paramObj, cleanFn);
  },  
  post: (postSlug, cleanFn) => {
    return jsonApiRequest(
      baseJoin("wp", "posts"),
      postSlug,
      {
        _fields: "id,slug,title,date,acf,content,has_blocks,block_data,featured_image,_links,_embedded",
        _embed: "wp:term"
      },
      cleanFn
    ).then(_.first);
  }, 
  page: (slug) => {
    return jsonApiRequest(baseJoin("wp", "pages"), {
      slug,
      _fields: "content"
    }).then(_.first);
  },
  discorderPostNext: (date, cleanFn) => {
    return jsonApiRequest(
      baseJoin("wp", "discorder"),
      {
        per_page: 1,
        before: date,
        _fields: "id,slug,title,date,excerpt,summary,acf"
      },
      cleanFn
    );
  },
  discorderPosts: ({ offset, size, tagId }, cleanFn) => {
    var paramObj = {
      offset,
      per_page: size,
      _fields: "id,slug,title,date,excerpt,summary,featured_image,_links,_embedded",
      _embed: "wp:term"
    };
    if (tagId) {
      paramObj.tags = tagId;
    }
    return jsonApiRequest(baseJoin("wp", "discorder"), paramObj, cleanFn);
  },
  discorderPost: (id, cleanFn) => {
    return jsonApiRequest(
      baseJoin("wp", "discorder"),
      {
        slug: id,
        _fields: "id,slug,title,date,acf,content,featured_image,_links,_embedded",
        _embed: "wp:term"
      },
      cleanFn
    ).then(_.first);
  },
  issues: ({ offset, size }, cleanFn) => {
    var paramObj = {
      offset,
      per_page: size,
      //order: "asc",
//      acf_format: "standard",
//      _fields: "id,slug,name,date,excerpt,description,acf"
    };
    return jsonApiRequest(baseJoin("wp", "discorder_issue"), paramObj, cleanFn);
  },
  issue: (id, cleanFn) => {
    return jsonApiRequest(
      baseJoin("wp", `discorder_issue/${id}`),
      {
        slug: id,
        acf_format: "standard",
        _fields: "id,slug,name,date,excerpt,description,acf"
      },
      cleanFn
    );
  },
  radioShows: ({ offset, size, slug, fields }, cleanFn) => {
    var params = {
      offset,
      per_page: size,
      _fields: fields? fields.join(",") : wpShowProperties.join(",")
    };
    if (slug) {
      params.slug = _.join(_.flatten([slug]), ",");
    }
    return jsonApiRequest(baseJoin("wp", "radio"), params, cleanFn);
  },
  radioShow: (identifier, cleanFn) => {
    return jsonApiRequest(
      baseJoin("wp", "radio"),
      {
        slug: identifier,
        _fields:[...wpShowProperties,'episodes'].join(","),
        _embed: "wp:featuredmedia"
      },
      cleanFn
    ).then(_.first);
  },
  episodes: ({ id, offset, size }, cleanFn) => {
    var params = {
      orderby: "date",
      offset,
      per_page: size,
    };
    if (id){
      params.djland_show_id = id;
    }
      return jsonApiRequest(baseJoin("wp", "playlist"), params)
      .then((episodes) => {
        return episodes.map(cleanFn);
      });
  },
  episode: (id, cleanFn) => {
    return jsonApiRequest(baseJoin("wp", `playlist/${id}`), {
    }).then(cleanFn);
  },
  genres: ({offset, size}, cleanFn) => {
    return jsonApiRequest(baseJoin("wp", "genres"), {
      offset,
      per_page: size,
//      _fields: "id,slug,name,description"
    }, cleanFn);
  },
  radioShowsWithGenre: (genre, cleanFn) =>{
    return jsonApiRequest(baseJoin("wp", `genres/radio-with-genre`), {
      genre,
      per_page: 100
//      _fields: "id,slug,name"
    }).then((results) => {
      var ids = _.map(results, "id");
      if (_.isEmpty(ids)) {
        return [];
      }
      return jsonApiRequest(baseJoin("wp", "radio"), {
        include: ids.join(","),
        per_page: 100,
        _fields: wpShowProperties.join(","),
      }, cleanFn)
    });
  },
  charts: ({offset, size}, cleanFn) => {
    return jsonApiRequest(baseJoin("wp", "chart"), {
      offset,
      per_page: size
    }, cleanFn);
  },
  
  //for usage from inside node
  overrideCachedRequestFn: (fn) => {
    doRequest = fn;
  }
  
};

export default WordpressApi;
