import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";

import DebouncingHover from "./modules/DebouncingHover";
import { Div } from "./components/Elements";
import "./Bar.scss";
import Logo from "./Logo";
//import _ from "lodash";
import BarCommon from "./BarCommon";
import Icon from "./components/Icon";
import StaticData from "./StaticData";

function BarDesktop() {
  const [LinkTree, setLinkTree] = useState([
    {
      key: "listen",
      to: "/listen",
      expanded: true,
      children: [
        { key: "program guide", to: "/program-guide" },
        { key: "top 50 charts", to: "/charts" },
        { key: "fanta records", to: "https://linktr.ee/fantarecs" }
      ]
    },
    {
      key: "read",
      to: "/read",
      children: [
        { key: "past issues", to: "/read/issues" },
        { key: "get discorder", to: "/get-discorder" },
        { key: "blog", to: "/read/blog" },
      ]
    },
    {
      key: "more",
      children: [
        { key: "info", to: "/info" },
        { key: "get involved", to: "/get-involved" },
        { key: "events", to: "/events" },
        {
          key: "shop",
          to: "https://citr-radio-and-discorder-magazine.square.site/"
        },
        { key: "contact", to: "/contact" }
      ]
    },
    {
      key: "support",
      to: StaticData.supportUrl
    }
  ]);

  function ToggleExpand(linkData, desktop) {
    setLinkTree((oldLinkTree) => {
      var i = oldLinkTree.indexOf(linkData);
      return oldLinkTree.map((link, index) => {
        if (index === i) {
          return { ...link, expanded: !linkData.expanded };
        } else if (desktop) {
          return { ...link, expanded: false };
        } else {
          return link;
        }
      });
    });
  }

  var Common = BarCommon({ ToggleExpand });

  function devAidHandler(event) {
    console.log("event");
    const keyName = event.key;

    if (keyName === "Control") {
      console.log("control");
      var expanded = AnyExpanded();
      if (expanded) {
        ToggleExpand(null, true);
      } else {
        ToggleExpand(LinkTree[0]);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keyup", devAidHandler, false);
    return () => {
      document.removeEventListener("keyup", devAidHandler, false);
    };
  });

  function Hover({ children, link, expands, className, style }) {
    return DebouncingHover({
      children,
      link,
      className,
      style,
      onEnterFn: () => {
        if (expands.expanded) {
          return;
        }
        setTimeout(() => {
          ToggleExpand(expands, true);
        }, 10);
      },
      onLeaveFn: () => {
        ToggleExpand(null, true);
      },
      leaveTimeout: 500,
      id: "bar"
    });
  }

  function MakeLink(linkData, style) {
    style = { ...style };

    if (linkData.children) {
      return (
        <Hover
          link={linkData}
          expands={linkData}
          className={"NavLink"}
          style={{
            opacity: linkData.expanded || !AnyExpanded() ? "1" : "0.3",
            transition: "opacity 0.3s ease",
            ...style
          }}
          key={linkData.key}
        >
          {linkData.key}
        </Hover>
      );
    } else if (linkData.to) {
      var target = linkData.to.startsWith("http") ? "_blank" : "";
      
      return (
        <Link
          key={linkData.key}
          to={linkData.to}
          target={target}
          className={"NavLink"}
          style={{
            opacity: linkData.expanded || !AnyExpanded() ? "1" : "0.3",
            ...style
          }}
        >
          <div>{linkData.key}</div>
        </Link>
      );
    }
  }

  function AnyExpanded() {
    var is = LinkTree.find((l) => {
      return l.expanded;
    });
    return is;
  }

  function DesktopSubNavLinks(props) {
    var l = LinkTree.find((linkData) => linkData.expanded);
    if (l && l.children) {
      return (
        <Hover
          className="DesktopSubNavLinks"
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "0",
            height: "40px"
          }}
          expands={l}
        >
          {l.children.map((l) =>
            MakeLink(l, {
              padding: "0 4.5px",
              opacity: "0.3"
            })
          )}
        </Hover>
      );
    }
  }

  return (
    <header style={{}}>
      <Div
        className={"Bar Desktop"}
        style={{
          height: AnyExpanded() ? "104px" : "72px",
          width: "calc(100% - 100px)",
          transition: "all 0.3s ease",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap",
          padding: "25px 0px 23px 0px",
          boxSizing: "border-box"
        }}
      >
        <Logo large />
        <Common.DisappearsWhenSearching>
          {LinkTree.map(MakeLink)}
        </Common.DisappearsWhenSearching>

        <Common.SearchInput />
        <Div
          className="search-icon"
          style={{
            cursor: "pointer",
            borderLeft: Common.searching ? "" : "2px solid var(--accent)",
            justifyContent: "end",
            minWidth: "35px",
            paddingRight: "5px"
          }}
          onClick={() => Common.setSearching(!Common.searching)}
        >
          {Common.searching ? (
            <Icon Close />
          ) : (
            <Icon
              className="search-icon"
              Search
              style={{
                opacity: AnyExpanded() ? "0.3" : "1"
              }}
            />
          )}
          {}
        </Div>

        <Common.DisappearsWhenSearching>
          <DesktopSubNavLinks />
        </Common.DisappearsWhenSearching>
      </Div>
    </header>
  );
}

export default BarDesktop;
