import { useState, useEffect } from 'react';
import { Div, Header } from "../components/Elements";
import GutenbergBlockLoop from '../components/Gutenberg/GutenbergBlockLoop';
import LegacyBlockContent from '../components/Gutenberg/LegacyBlockContent';
import GetInvolved from '../components/GetInvolved';
import styles from './BlogPost.module.css';
const { useLoaderData } = require("react-router-dom");


function BlogPost() {

  // Save the post from the API to the state
  let [post, setPost] = useState(useLoaderData()[0]);
  let [date, setDate] = useState("");

  // Assume that the post is not a Gutenberg post
  let [isGutenberg, setIsGutenberg] = useState(false);

  // When the API data is loaded, check if the post is a Gutenberg post via the has_blocks property
  useEffect(() => {
    const date = new Date(post.date);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    setDate(`${month}.${day}.${year}`);
    // If the post has blocks, set the state to true, used in the ternary operator below
    if (post.has_blocks) setIsGutenberg(true);
  }
  , [post]);

  return (
    <>
      <div className={`${styles['blog-post']}`}>

        <div className={`${styles['blog-header']}`}>
          <p>{date}</p>
          <h1>{post.title.rendered}</h1>
          <p className={`${styles['blog-author']}`}>Written by <span>{post.author_info.name}</span></p>
        </div>

        <div className={`${styles['blog-featured-image']}`}>
          {post.featured_image_url && <img src={post.featured_image_url} alt={post.title.rendered} />}
        </div>

        <div>
          {isGutenberg ? <GutenbergBlockLoop blocks={post.block_data} /> : <LegacyBlockContent content={post.content} />}
        </div>

      </div>
      <div>
        <GetInvolved />
      </div>
    </>
  );
}

export default BlogPost;
