import React, { createContext, useState, useEffect } from "react";
import _ from "lodash";

import Data from "./Data";

export const RadioContext = createContext();

function RadioProvider({ children }){
  const [schedule/*, setSchedule */] = useState([]);

  const [nowPlaying/*, setNowPlaying*/] = useState(null);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [playable, setPlayable] = useState([]);
  const [lastShowAndNextShow, setLastShowAndNextShow] = useState(null);
  const playEpisode = (content) => {

    var nowPlaying = { episode: content.episode };
    setPlayable(o => nowPlaying);
    setShouldPlay(true);
//    setIsVisible(true);
  };

  const playLiveStream = () => {
    setLastShowAndNextShow(null);
    setPlayable([]);
    setShouldPlay(true);

  }

  // TODO: wait 1 min.. THEN enable subscribing to gun/websocket
  useEffect(() => {
    // thisShowAndNextShow is just comma separated string of show names
    const handleUpdate = ({json, show: thisShowAndNextShow}) => {
//          console.log("gun schedule", JSON.parse(json));
          if (_.isArray(playable) && lastShowAndNextShow !== thisShowAndNextShow){
  //          Data.getOnAirShows(2, [{schedule: data}])
              setPlayable(JSON.parse(json));
              setLastShowAndNextShow(thisShowAndNextShow);
          } else {
//            console.log("not array, or, no change to this and next", playable, lastShow, thisShowAndNextShow);
            
          }

      };
    return Data.subscribe("citr/now", handleUpdate);

  }, [lastShowAndNextShow, playable]);

  return (
    <RadioContext.Provider value={{
      schedule, 
      playable, 
      playEpisode, 
      playLiveStream, 
      shouldPlay, 
      setShouldPlay, 
      nowPlaying}}>

      {children}
    </RadioContext.Provider>
  );
};

export default RadioProvider;
