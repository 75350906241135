import parse from 'html-react-parser';

// This component is used to render posts that were created with the classic editor in WordPress
// The plugin on the WP side adds a has_blocks & block_data property to the post object
function LegacyBlockContent( props ) {

    // Destructure the content from the WordPress API
    const { content } = props;

    // Parse the HTML content
    const contentAsHTML = parse(content.rendered);

    return (
        <div className='wp-legacy-content'>{ contentAsHTML }</div>
    );

}

export default LegacyBlockContent;