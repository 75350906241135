import gi from "./GetInvolved.module.css";
import { Button } from "./Elements";
import Marquee from "react-fast-marquee";

function GetInvolved({ to, name }) {
  return (
    <div className={gi["get-involved"]}>
        <Marquee className={gi["get-involved__ticker"]} autoFill={true}>&mdash; GET INVOLVED &nbsp;</Marquee>
        <div className={gi["get-involved__content"]}>
            <h4>OUR SUBSCRIPTION RATES CAN’T BE BEAT! YOU PAY THE POSTAGE AND THAT’S IT!</h4>
            <Button className={gi["get-involved__button--large"]}>Get Involved!</Button>
        </div>
    </div>
  );
}

export default GetInvolved;